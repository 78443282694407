<template>
    <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="initialize" :configurable="true" :warningAlert="warningAlert" :warningTitle="warningTitle" :queryParam="queryParam">
        <el-table slot="elList" ref="productListTable" :data="dataSource.Result" border @select="selectionChange" @select-all="selectionChange" @selection-change="handleSelectionChange" highlight-current-row>
            <el-table-column type="selection" width="55" v-if="config.isSelectionDisplay"></el-table-column>
            <el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams"
                             :key="index"
                             :prop="col.FieldName"
                             :label="col.DisplayName"
                             :render-header="bindFilter(queryParam,col)"
                             :fixed="index==0&&(!config||!config.isDetailDisplay)"
                             :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                             v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<4))">
                <template slot-scope="scope">
                    <el-button v-if="col.FieldName==='ProductNo'" type="text" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</el-button>
                    <span v-else>  {{ scope.row[col.FieldName] }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="config.isOrderOperation" label="下单数量" :min-width="60">
                <template slot-scope="scope">
                    <el-input type="number" size="small" min="0" v-model.number="scope.row.ProductNumber" @change="changeNumber(scope.row)" placeholder="数量"></el-input>
                </template>
            </el-table-column>
            <el-table-column v-if="config.isOrderOperation" label="包装单位" :min-width="70">
                <template slot-scope="scope">
                    <el-select size="small" v-model="scope.row.ProductUnitName" :maxlength="200">
                        <el-option v-for="item in scope.row.UnitList"
                                   :key="item.ProductUnitName"
                                   :label="item.IsPrimary ? item.ProductUnitName : (item.ProductUnitName+'('+item.RelationToPrimary+item.PrimaryUnitName+')')"
                                   :value="item.ProductUnitName">
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
        </el-table>
    </fixed-list>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("onRefreshProductList", () => this.initialize());
            });
            this.initialize();//加载表格数据
            this.config.isDetailDisplay = false;
        },
        data() {
            return {
                warningAlert: false, //启用警告
                warningTitle: '', //警告信息
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    Params: {
                        CustomerCode:''
                    }
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0,
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isSelectionDisplay: false,
                currentCCode: '',
                currentType: '',
                CustomerCode:''
            },
            selectCondition: {
                ProductStatus: 200
            }
        },
        methods: {
            onChangeEditDataSource: function (data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            onDataSourceChange(ds) {
                var _this = this;
                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onSelectedRow", val);
            },
            initialize: function () {
                var _this = this;
                if (_this.selectCondition) {
                    _this.Utils.extend(_this.queryParam.params, _this.selectCondition);
                }
                var url = "omsapi/product/getproductinfos";
                console.log("ShopNo", _this.config.ShopNo);
                if (_this.config.ShopNo != undefined && _this.config.ShopNo != '') {
                   // _this.queryParam.Params.ShopNos = { fieldValue: _this.config.ShopNo, fieldName: 'ShopNos', fieldType: 1, fieldHidden: true };
                   _this.queryParam.Params.ShopNos = this.config.ShopNo;

                } else {
                    url = "omsapi/product/getproductinfos"//"omsapi/product/getallproductlist";
                }
                if (_this.config.type != undefined) {
                    //_this.queryParam.Params.ProductStatus = { fieldValue: [100], fieldName: 'ProductStatus', fieldType: 3, fieldHidden: true };
                    _this.queryParam.Params.ProductStatus = 100;
                }
                if(_this.config.CustomerCode != null && _this.config.CustomerCode != '' && _this.config.CustomerCode != undefined)
                {
                    _this.queryParam.Params.CustomerCode = this.config.CustomerCode;
                    //_this.queryParam.params.CustomerCode = { fieldValue: _this.config.CustomerCode, fieldName: 'CustomerCode', fieldType: 1, fieldHidden: true };
                }
                this.$ajax.query(url, "post", _this.queryParam, (result) => {
                    _this.dataSource = result;
                });
            },
            selectionChange(selection) {
                this.warningAlert = false;
                this.warningTitle = '';
                this.config.ProductNos = this.removeDuplicatedItem(this.config.ProductNos);//去重
                for (var i = 0; i < this.config.ProductNos.length; i++) {
                    for (var j = 0; j < selection.length; j++) {
                        if (this.config.ProductNos[i] == selection[j].ProductNo) {
                            this.warningTitle = this.warningTitle + selection[j].ProductNo + ',';
                            break;
                        }
                    }
                }
                this.warningAlert = this.warningTitle != '' && this.warningTitle.length > 0;
                this.warningTitle = this.warningAlert ? `商品${this.warningTitle}已存在，继续添加将新增商品行` : '';
            },
            removeDuplicatedItem(arr) {
                for (var i = 0; i < arr.length - 1; i++) {
                    for (var j = i + 1; j < arr.length; j++) {
                        if (arr[i] == arr[j]) {
                            arr.splice(j, 1);
                            j--;
                        }
                    }
                }
                return arr;
            },
            changeNumber: function (row) {
                this.$refs.productListTable.toggleRowSelection(row, true);
                //for (var i = 0; i < this.dataSource.Result.length; i++) {
                //    if (this.dataSource.Result[i].ProductNo == productNo) {
                //        this.$refs.productListTable.toggleRowSelection(this.dataSource.Result[i], true);
                //        break;
                //    }
                //}
            }
        },

    }
</script>
